import Vue from "vue";
import Vuex from "vuex";
import Localbase from "localbase";
import axios from "axios";
//import { parseString } from "xml2js";
const { parse } = require("rss-to-json");

/* let db = new Localbase("db");
db.config.debug = false; */

const worksFolder = "worksdata";
const venturesFolder = "venturesdata";
const studioFolder = "studiodata";
const rentalsFolder = "rentalsdata";

Vue.use(Vuex);

//const publicPath = process.env.NODE_ENV === "production" ? "projects/temp/xrm-assets/" : "./";
const publicPath = process.env.NODE_ENV === "production" ? "site/" : "./";
const rssUrl = "https://rss.app/feeds/VvLm9zvhg7hdG8XT.xml";

function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj);
}

export default new Vuex.Store({
  state: {
    appTitle: process.env.VUE_APP_TITLE,
    about: [],
    works: [],
    news: { status: null, content: [] },
    studioImages: [],
    ventures: [],
    rentalImages: [],
    publicPath: publicPath,
    contact: {
      email: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    },
  },
  mutations: {
    getAbout(state, about) {
      state.about = about;
    },
    getContact(state, contact) {
      state.contact = contact;
      console.log("state.contact: ", state.contact);
    },
    getWork(state, works) {
      state.works = works;
    },
    getStudioImages(state, studioImages) {
      state.studioImages = studioImages;
    },
    getNews(state, news) {
      state.news = news;
    },
    getVentures(state, ventures) {
      state.ventures = ventures;
    },
    getRentalImages(state, rentalImages) {
      state.rentalImages = rentalImages;
    },
  },
  actions: {
    getAbout({ commit }) {
      axios
        .get(publicPath + "/about.json")
        .then((res) => {
          if (res.data) {
            res.data.forEach((item, index) => {
              item.id = index;
            });
          }
          commit("getAbout", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
    getContact({ commit }) {
      axios
        .get(publicPath + "/contact.json")
        .then((res) => {
          commit("getContact", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
    getWork({ commit }) {
      axios
        .get(publicPath + worksFolder + "/works.json")
        .then((res) => {
          if (res.data) {
            res.data.forEach((item, index) => {
              item.id = index;
            });
          }
          commit("getWork", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
    getStudioImages({ commit }) {
      axios
        .get(publicPath + studioFolder + "/studio.json")
        .then((res) => {
          if (res.data) {
            res.data.forEach((item, index) => {
              item.id = index;
            });
          }
          commit("getStudioImages", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
    getVentures({ commit }) {
      axios
        .get(publicPath + venturesFolder + "/ventures.json")
        .then((res) => {
          if (res.data) {
            res.data.forEach((item, index) => {
              item.id = index;
            });
          }
          res.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          commit("getVentures", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
    getNews({ commit }) {
      parse(rssUrl)
        .then((rss) => {
          if (rss && rss.items && rss.items.length) {
            commit("getNews", { status: "success", content: rss.items });
          } else {
            commit("getNews", { status: "success", content: [] });
          }
        })
        .catch((err) => {
          console.error("error: ", err);
          commit("getNews", { status: "error", content: [] });
        });
    },
    getRentalImages({ commit }) {
      axios
        .get(publicPath + rentalsFolder + "/rental-images.json")
        .then((res) => {
          console.log("res: ", res);
          if (res.data) {
            res.data.forEach((item, index) => {
              item.id = index;
            });
          }
          commit("getRentalImages", res.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    },
  },
  getters: {
    about(state) {
      return [...state.about].sort((a, b) => a.order - b.order);
    },
    contact(state) {
      return state.contact;
    },
    workByReleaseDate(state) {
      let ret = [];
      state.works.forEach((work) => {
        ret.push({
          ...work,
          releaseDate: work.releaseDate ? new Date(work.releaseDate) : new Date("1900-01-01"),
          logo: `${publicPath}${worksFolder}/${work.folder}/${work.logo}`,
          posterLg: `${publicPath}${worksFolder}/${work.folder}/${work.posterLg}`,
          posterSm: `${publicPath}${worksFolder}/${work.folder}/${work.posterSm}`,
          still: `${publicPath}${worksFolder}/${work.folder}/${work.still}`,
        });
      });
      return ret.sort((a, b) => b.releaseDate - a.releaseDate);
    },
    studioImages(state) {
      let ret = [];
      state.studioImages.forEach((item) => {
        ret.push({
          image: `${publicPath}${studioFolder}/${item.image}`,
          id: item.id,
        });
      });
      return ret;
    },
    rentalImages(state) {
      let ret = [];
      state.rentalImages.forEach((item) => {
        ret.push({
          image: `${publicPath}${rentalsFolder}/${item.image}`,
          id: item.id,
        });
      });
      return ret;
    },
    ventures(state) {
      let ret = [];
      state.ventures.forEach((item) => {
        if (!item.logo) return;
        ret.push({
          ...item,
          logo: item.logo ? `${publicPath}${venturesFolder}/${item.logo}` : "",
        });
      });
      return ret;
    },
    news(state) {
      let ret = [];
      state.news.content.forEach((item, index) => {
        let obj = {
          author: item.author,
          title: item.title,
          created: item.created || 0,
          link: item.link,
          id: index,
          image: getNested(item, "media", "thumbnail", "url") || `${publicPath}images/xrm_rss_bumper.png`,
        };
        ret.push(obj);
      });
      return { status: state.news.status, content: ret };
    },
  },
  modules: {},
});
