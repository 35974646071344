<template>
  <v-dialog v-model="dialogVisible" max-width="500" v-click-outside="outsideClose">
    <v-card class="contact-card" :style="{ backgroundImage: 'url(' + $store.state.publicPath + './images/contact-texture.jpg' + ')' }">
      <v-card-title class="text-h5 white--text" :style="{ backgroundImage: 'url(' + $store.state.publicPath + './images/contact-texture.jpg' + ')' }">
        <p class="text-center" style="width: 100%; margin-bottom: 0px;">CONTACT US</p>
      </v-card-title>

      <v-card-text class="mt-4 pb-0 d-flex justify-center">
        <v-icon large color="primary">mdi-email</v-icon>
        <a class="email-link ml-3" href="mailto:info@xrmmedia.com">{{ contact.email }}</a>
      </v-card-text>

      <v-card-text class="mt-4 pb-0 d-flex justify-center">
        <v-icon large color="primary">mdi-map-marker</v-icon>
        <a class="address-link ml-3" href="https://goo.gl/maps/o688YzKjfS7TvbKQ8">{{ contact.address.street }},<br />{{ contact.address.city }}, {{ contact.address.state }} {{ contact.address.zip }}, {{ contact.address.country }}</a>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn class="close-btn my-3" color="black white--text" @click="$emit('close')">
          CLOSE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialogVisible"],
  mounted() {
    // Contact information is now loaded asynchronously via the public/contact.json file.
    this.$store.dispatch("getContact");
  },
  data() {
    return {};
  },
  methods: {
    outsideClose(evt) {
      const target = evt.target.className;
      if (target === "v-overlay__content" || target === "v-overlay__scrim") {
        this.$emit("close");
      }
    },
  },
  computed: {
    contact: {
      get() {
        return this.$store.getters.contact;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-card {
  .v-card__title,
  .v-card__text,
  .v-card__actions {
    z-index: 10;
    position: relative;
  }

  .v-card__title {
    text-shadow: 2px 2px 4px #000000;
    color: #fff;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 900;
    font-size: 2rem;
  }

  .v-card__text {
    text-align: center;
    .address-link,
    .email-link {
      font-family: "Montserrat", sans-serif !important;
      font-weight: 400;
      text-decoration: none;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #000;
      text-align: left;
    }
  }

  .close-btn {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
</style>
