import { ReverseSubtractEquation } from "three";
import Vue from "vue";
import VueRouter from "vue-router";
import { Goto } from "vuetify/lib/services";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: "Home" },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "About Page" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/work",
    name: "Work",
    meta: { title: "Our Work" },
    component: () => import(/* webpackChunkName: "work" */ "../views/Work.vue"),
  },
  // {
  //  path: "/studio",
  //  name: "Studio",
  //  meta: { title: "Our Studio" },
  //  component: () => import(/* webpackChunkName: "studio" */ "../views/Studio.vue"),
  // },

  // {
  //  path: "/studio",
  //  name: "Studio",
  //  meta: { title: "Our Studio" },
  //  component: () => import(/* webpackChunkName: "studio" */ "../views/Studio-Rentals.vue"),
  // },
  {
    path: "/ventures",
    name: "Ventures",
    meta: { title: "Our Ventures" },
    component: () => import(/* webpackChunkName: "ventures" */ "../views/Ventures.vue"),
  },
  {
    path: "/news",
    name: "News",
    meta: { title: "In The News" },
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  },
  {
    // If Page Not Found, Redirect to Homepage (instead of 404)
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/site/",
  routes,
});

router.beforeEach((to, from, next) => {
  //document.title = process.env.VUE_APP_TITLE + " - " + to.meta.title;
  document.title = "XRM - " + to.meta.title;

  /* const incoming = to.redirectedFrom;

  for (let a = 0; a < redirects.length; a++) {
    let redirect = redirects[a];
    let redirectPath = redirects[a].redirectPath;
    for (let b = 0; b < redirect.values.length; b++) {
      let value = redirect.values[b];
      if (incoming && incoming.includes(value)) {
        router.push(redirectPath);
        return;
      }
    }
  } */

  next();
});

/* router.afterEach((to, from) => {
  goTo(0, { duration: 0 });
}); */

export default router;
