<template>
  <div style="z-index: 100">
    <!-- Desktop Menu -->
    <v-app-bar app dark color="white" class="hidden-md-and-down" height="64px">
      <v-app-bar-title
        ><v-img
          :src="$store.state.publicPath + 'images/xrm_horizontal.svg'"
          max-width="150"
        ></v-img
      ></v-app-bar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          color="primary"
          v-for="item in nav"
          :key="item.text"
          :to="item.to"
          :href="item.href"
          :title="item.title"
          text
          >{{ item.text }}</v-btn
        >
      </v-toolbar-items>
      <v-btn
        color="primary"
        depressed
        elevation="2"
        :title="contact.title"
        @click="$emit('contactDialog')"
        >{{ contact.text }}</v-btn
      >
    </v-app-bar>

    <!-- Mobile Menu -->
    <v-app-bar
      app
      dark
      color="white darken-3"
      class="hidden-lg-and-up"
      height="64px"
    >
      <v-app-bar-title
        ><v-img
          :src="$store.state.publicPath + 'images/xrm_horizontal.svg'"
          max-width="150"
        ></v-img
      ></v-app-bar-title>
      <v-spacer></v-spacer>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon color="primary" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-card>
          <v-app-bar text color="white darken-2">
            <v-app-bar-title
              ><v-img
                :src="$store.state.publicPath + 'images/xrm_horizontal.svg'"
                max-width="150"
              ></v-img
            ></v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="dialog = false">
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>
          </v-app-bar>

          <v-list>
            <v-list-item
              v-for="(item, index) in nav"
              :key="index"
              :to="item.to"
              :href="item.href"
              target="_blank"
              @click="dialog = false"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title :title="item.title">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="emitContact" :title="contact.title">
              <v-list-item-action v-if="contact.icon">
                <v-icon>{{ contact.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title :title="contact.title">{{
                  contact.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    publicPath: process.env.NODE_ENV === "production" ? "/projects/todo/" : "/",
    drawer: null,
    deleteAll: false,
    navItems: [
      { text: "Home", title: "Home", icon: "", to: "/" },
      { text: "About Us", title: "About Us", icon: "", to: "/about" },
      { text: "Our Work", title: "Our Work", icon: "", to: "/work" },
      {
        text: "Our Studio",
        title: "Our Studio",
        icon: "",
        to: "",
        href: "https://xrmmediastudios.com",
      },
      // { text: "Our Studio", title: "Our Studio", icon: "", to: "/studio" },
      // { text: "Studio Rentals", title: "Studio Rentals", icon: "", to: "/studio-rentals" },
      {
        text: "Our Ventures",
        title: "Our Ventures",
        icon: "",
        to: "/ventures",
      },
      { text: "In The News", title: "In The News", icon: "", to: "/news" },
      { text: "Contact", title: "Contact", icon: "", to: "/contact" },
    ],
  }),
  computed: {
    nav() {
      return this.navItems.filter((item) => {
        return !item.title.toLowerCase().includes("contact");
      });
    },
    contact() {
      const ret = this.navItems.filter((item) => {
        return item.title.toLowerCase().includes("contact");
      });
      if (ret.length) return ret[0];
    },
  },
  components: {},
  methods: {
    emitContact() {
      this.dialog = false;
      this.$emit("contactDialog");
    },
  },
};
</script>

<style lang="scss">
.v-toolbar__content,
.v-list-item {
  font-family: "Montserrat", sans-serif;
}

.v-btn {
  font-weight: 500;
  &::before {
    background-color: transparent !important;
  }
}

.v-btn--active {
  &::before,
  .v-ripple__container {
    opacity: 0 !important;
  }

  .v-btn__content {
    font-weight: 900;
    color: #000;
  }
}

.v-list-item--active {
  &.v-list-item--link:before {
    background-color: #18a0fb !important;
    opacity: 1 !important;
  }

  .v-list-item__title {
    font-weight: 900;
    color: #fff;
    z-index: 1;
  }
}
</style>
