<template>
  <v-app id="inspire" :dark="false">
    <navigation @contactDialog="contactDialog = true"></navigation>
    <contact-dialog :dialogVisible="contactDialog" @close="contactDialog = false"></contact-dialog>
    <v-main>
      <routerView></routerView>
    </v-main>
  </v-app>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("getContact");
    this.$store.dispatch("getAbout");
  },
  data() {
    return {
      contactDialog: false,
    };
  },
  components: {
    navigation: require("@/components/shared/Navigation.vue").default,
    "contact-dialog": require("@/components/shared/ContactDialog.vue").default,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/global.scss";
</style>
